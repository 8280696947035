import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["output", "input", "preview", "upload", "filename"]

  readURL() {
    const input = this.inputTarget
    const output = this.outputTarget

    this.updatePreview(input.files, output)
  }

  updatePreview(files, target) {
    if (files && files[0]) {
      this.uploadTarget.classList.add('hidden')
      this.previewTarget.classList.remove('hidden')
      this.filenameTarget.innerText = files[0]?.name

      const tag = files[0].type === 'application/pdf' ? 'iframe' : 'img'
      const reader = new FileReader()

      reader.onload = function () {
        const el = document.createElement(tag)
        el.src = reader.result
        target.replaceChildren(el)
      }

      reader.readAsDataURL(files[0])
    }
  }

  handleDrop(event) {
    event.preventDefault()

    const files = event.dataTransfer.files
    if (files) {
      const input = this.inputTarget

      input.files = files

      if (this.hasOutputTarget) {
        const output = this.outputTarget
        this.updatePreview(files, output)
      } else {
        // trigger multi upload submit
        input.closest('form')?.submit()
      }
    }
  }

  handleDragOver(event) {
    event.preventDefault()
  }

  triggerUpload() {
    this.inputTarget.click()
  }

  openInNewTab() {
    const targetChild = this.outputTarget.children[0]
    if (targetChild && targetChild?.src) {
      window.open(targetChild.src, '_blank')
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-modal"
export default class extends Controller {
  open() {
    document.getElementsByTagName('body').classList.add('bg-blur')
    this.modal = new bootstrap.Modal(this.element, {
      keyboard: false
    })
    this.modal.show()
  }

  close() {
    document.getElementsByTagName('body').classList.remove('bg-blur')
    this.modal.hide()
  }
}

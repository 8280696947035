// Custom TailwindCSS modals for confirm dialogs

function insertConfirmModal(message, element) {

  let locale = document.body.getAttribute('datalocale')

  const cancelText = locale == 'en' ? 'Cancel' : 'Mégsem'
  const confirmText = locale == 'en' ? 'Confirm' : 'Megerősítés'

  let content = `
    <div id="confirm-modal" class="z-50 animated fadeIn fixed top-0 left-0 w-full h-full table rounded-lg dialog-border items-center justify-center p-0" style="background-color: rgba(0, 0, 0, 0.8);">
      <div class="table-cell align-middle">


        <div class="bg-white mx-auto rounded shadow p-8 max-w-lg">
        <div class="py-3 text-center mb-12">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="50px" height="50px" fill="#d60808"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c-8.8 0-16 7.2-16 16V272c0 8.8 7.2 16 16 16s16-7.2 16-16V144c0-8.8-7.2-16-16-16zm24 224a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z"/></svg>


        </div>
        <h4 class="text-slate-800 text-center mt-4 mb-8">${message}</h4>
        <hr class="border border-b-3 border-solid border-[#dee2e6] !w-full p-0 m-0"></hr>

          <div class="flex justify-center items-center mt-6 gap-4">
            <button data-behavior="cancel" class="modal-cancel w-full inline-block px-6 py-3 font-bold text-center uppercase align-middle transition-all rounded-lg cursor-pointer text-xs ease-in tracking-wide hover:shadow-md text-[#3a416f] bg-transparent ring-1 ring-inset ring-[#1e293b]">${cancelText}</button>
            <button data-behavior="commit" class="modal-commit w-full inline-block px-6 py-3 font-bold text-center uppercase align-middle transition-all rounded-lg cursor-pointer text-xs ease-in tracking-wide hover:shadow-md text-white bg-gradient-310 from-[#2152ff] to-[#21d4fd]">${confirmText}</button>
          </div>
        </div>
      </div>
    </div>
  `

  document.body.insertAdjacentHTML('beforeend', content)
  document.activeElement.blur()
  document.body.classList.add('fixed')
  return document.getElementById("confirm-modal")
}

Turbo.setConfirmMethod((message, element) => {
  let dialog = insertConfirmModal(message, element)
  const modalInner = document.getElementById('.confirm-modal')

  return new Promise((resolve, reject) => {

    dialog.querySelector("[data-behavior='cancel']").addEventListener("click", (event) => {
      dialog.remove()
      resolve(false)
      document.body.classList.remove('fixed')
    }, { once: true })

    dialog.querySelector("[data-behavior='commit']").addEventListener("click", (event) => {
      dialog.remove()
      resolve(true)
      document.body.classList.remove('fixed')
    }, { once: true })

    document.addEventListener("click", (event) => {
      let clickInside = element.contains(event.target)
      if (!clickInside) {
        dialog.remove()
        resolve(false)
        document.body.classList.remove('fixed')
      }
    }, { once: true })

  })

})



import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "currency", "bankAccount" ]

  connect() {
    this.updateCurrency()
  }

  updateCurrency() {
    const selectedOption = this.bankAccountTarget.options[this.bankAccountTarget.selectedIndex]
    const currency = selectedOption.getAttribute('data-currency-id')
    this.currencyTarget.value = currency
  }
}

// Example usage:
// <div data-controller="tooltip" data-tippy-content="Hello world"></div>

import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js"

export default class extends Controller {
  static values = {
    content: String
  }

  connect() {
    if (this.element.dataset.tippyMobileOnly) {
      if (window.innerWidth < '768') {
        this.init()
      }
    } else {
      this.init()
    }
  }

  disconnect() {
    if (this.tippy) {
      this.tippy.destroy()
    }
  }

  init() {
    let options = {}
    if (this.hasContentValue) {
      options['content'] = this.contentValue
    }

    this.tippy = tippy(this.element, options)
  }
}

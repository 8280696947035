import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="invoice-article"
export default class extends Controller {
  static targets = [ "gross", "net", "tax", "taxCode", "rate", "grossHuf", "netHuf", "taxHuf", "articleId", "controllingParam1",
                     "controllingParam2", "controllingParam3" ]
  static values = { url: String }
  calculate() {
    const grossElement = this.grossTarget
    const netElement   = this.netTarget
    const taxElement = this.taxTarget
    const rateElement = this.rateTarget
    const taxCodeElement = this.taxCodeTarget
    const taxValue = taxCodeElement.options[taxCodeElement.selectedIndex].text.split(" - ").slice(-1)

    let decimalPlaces = 2

    if (rateElement.value == 1)
    {
      decimalPlaces = 0
    }
    netElement.value = (grossElement.value / (1+taxValue/100)).toFixed(decimalPlaces)
    taxElement.value = (grossElement.value - netElement.value).toFixed(decimalPlaces)
    if (rateElement.value >1)
    {
      const grossHufElement = this.grossHufTarget
      const netHufElement = this.netHufTarget
      const taxHufElement = this.taxHufTarget
      grossHufElement.value = Math.round(grossElement.value * rateElement.value).toFixed(0)
      netHufElement.value = Math.round(netElement.value * rateElement.value).toFixed(0)
      taxHufElement.value = Math.round(taxElement.value * rateElement.value).toFixed(0)
    }
  }

  change() {
    const controllingParam1 = this.controllingParam1Target
    const controllingParam2 = this.controllingParam2Target
    const controllingParam3 = this.controllingParam3Target
    const taxCodeElement = this.taxCodeTarget
    const articleId = this.articleIdTarget
    let params = new URLSearchParams()
    params.append('id', this.articleIdTarget.value)
    get(`${this.urlValue}?${params}`)
    .then(r => r.response.json())
    .then(data => {
      controllingParam1.value = data.controlling_param1
      controllingParam2.value = data.controlling_param2
      controllingParam3.value = data.controlling_param3
      taxCodeElement.slim.setSelected(`${data.tax_code_id}`)
    })
  }
}
import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {
  connect() {
    let selectInvoiceStatus = document.querySelector('.select-invoice-status')
    let selectCancellation = document.querySelector('.select-cancellation')
    let selectCredited = document.querySelector('.select-credited')
    let selectAdvanced = document.querySelector('.select-advance')
    let selectRequester = document.querySelector('.select-requester')
    let selectStatus = document.querySelector('.select-status')
    const setInvoiceInputFields = (newVal) =>
    {
      selectInvoiceStatus.slim.enable()
      selectCredited.slim.disable()
      selectCancellation.slim.disable()
      selectAdvanced.slim.disable()
      selectRequester.slim.disable()

      if (newVal[0].text === 'Általános') {selectRequester.slim.enable(); selectAdvanced.slim.enable()}
      if (newVal[0].text === 'Storno') selectCancellation.slim.enable()
      if (newVal[0].text === 'Jóváíró') selectCredited.slim.enable()
      if ((newVal[0].text === 'Díjbekérő') || (newVal[0].text === 'Nyugta')) {
         selectStatus.slim.setSelected('disabled')
      }
      //   selectStatus.slim.disable()
      // }
      // else {
      //   selectStatus.slim.enable()
      // }
    }

    this.select = new SlimSelect({
      select: this.element,
      events: {
        afterChange: (newVal) => {
          if (this.element === selectInvoiceStatus)
          {
            setInvoiceInputFields(newVal)
          }
        }
      }
    })
    // if (selectInvoiceStatus && selectInvoiceStatus.selectedOptions[0].text === 'Storno') selectCancellation.slim.enable()
    // if (selectInvoiceStatus && selectInvoiceStatus.selectedOptions[0].text === 'Jóváíró') selectCredited.slim.enable()
    // if (selectInvoiceStatus && selectInvoiceStatus.selectedOptions[0].text === 'Általános') {selectRequester.slim.enable(); selectAdvanced.slim.enable()}
  }

  disconnect() {
    this.select.destroy()
  }

}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sort"
export default class extends Controller {
  connect() {
    new SlimSelect({
      select: this.element,
      showSearch: true,
      settings: {
        allowDeselect: true
      }
    })
  }
}

import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { get } from "@rails/request.js"

// Connects to data-controller="invoice"
export default class extends Controller {
  static targets = ["currency", "exchangeRate", "partner", "exchangeDate", "invoiceId",
                    "gross", "net", "tax", "taxCode", "rate", "grossHuf", "netHuf", "taxHuf",
                    "articleId", "controllingParam1", "controllingParam2", "controllingParam3" ]

  static values = { url: String , url2: String, loadDataUrl: String, invoiceReportingUrl: String }

  connect() {
    toastr.options = {
      "closeButton": true,
      "positionClass": "toast-top-center",
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "3000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "progressBar": true,
      "hideMethod": "fadeOut"
    }
  }

  partnerChange() {
    let params = new URLSearchParams()
    params.append('invoice_id', this.invoiceIdTarget.value)
    params.append('partner', this.partnerTarget.value)
    params.append('exchange_date', this.exchangeDateTarget.value)
    get(`${this.urlValue}?${params}`)
    .then(r => r.response.json())
    .then(data => {
      this.currencyTarget.slim.setSelected(`${data.currency_id}`)
    })
    if (this.partnerTarget.value) {
      fetch(`${this.url2Value}?${params}`, {
        headers: {
          Accept: "text/vnd.turbo-stream.html"
        }
      })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
    }
  }

  currencyChange() {
    let params = new URLSearchParams()
    params.append('partner', this.partnerTarget.value)
    params.append('currency', this.currencyTarget.value)
    params.append('exchange_date', this.exchangeDateTarget.value)
    get(`${this.urlValue}?${params}`)
      .then(r => r.response.json())
      .then(data => {
       this.exchangeRateTarget.value = data.exchange_rate
      })
  }

  calculate(event) {
    const grossElement   = this.grossTarget
    const netElement     = this.netTarget
    const taxElement     = this.taxTarget
    const rateElement    = this.rateTarget
    const taxCodeElement = this.taxCodeTarget
    const taxValue       = taxCodeElement.options[taxCodeElement.selectedIndex].text.split(" - ").slice(-1)

    let decimalPlaces = 2

    if (rateElement.value == 1) {
      decimalPlaces = 0
    }

    if (event.target === grossElement || event.target === taxCodeElement) {
      netElement.value = (grossElement.value / (1 + taxValue / 100)).toFixed(decimalPlaces);
    }
    if (event.target === netElement || event.target === taxCodeElement) {
      grossElement.value = (netElement.value * (1 + taxValue / 100)).toFixed(decimalPlaces);
    }

    taxElement.value = (grossElement.value - netElement.value).toFixed(decimalPlaces)

    if (rateElement.value >1) {
      const grossHufElement = this.grossHufTarget
      const netHufElement   = this.netHufTarget
      const taxHufElement   = this.taxHufTarget
      grossHufElement.value = Math.round(grossElement.value * rateElement.value).toFixed(0)
      netHufElement.value   = Math.round(netElement.value * rateElement.value).toFixed(0)
      taxHufElement.value   = Math.round(taxElement.value * rateElement.value).toFixed(0)
    }
  }

  change() {
    const controllingParam1 = this.controllingParam1Target
    const controllingParam2 = this.controllingParam2Target
    const controllingParam3 = this.controllingParam3Target
    const taxCodeElement    = this.taxCodeTarget
    const articleId         = this.articleIdTarget

    let params = new URLSearchParams()

    params.append('id', articleId.value)
    get(`${this.loadDataUrlValue}?${params}`)
      .then(r => r.response.json())
      .then(data => {
    controllingParam1.value = data.controlling_param1
    controllingParam2.value = data.controlling_param2
    controllingParam3.value = data.controlling_param3
    taxCodeElement.slim.setSelected(`${data.tax_code_id}`)
    })
  }

  runInvoiceReporting(event) {
    event.preventDefault()

    const url = this.invoiceReportingUrlValue
    const button = document.getElementById('run-invoice-reporting-button')
    const buttonWrapper = document.getElementById('invoice-reporting-wrapper')
    buttonWrapper.disabled = true
    buttonWrapper.classList.add('ponter-events-none')
    button.classList.add('animate-spin')

    fetch(url, {
      method: 'GET',
      headers: { 'Accept': 'application/json' }
    })
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          throw err
        })
      }
      return response.json()
    })
    .then(data => {
      buttonWrapper.disabled = false
      buttonWrapper.classList.remove('ponter-events-none')
      button.classList.remove('animate-spin')
      toastr.success(data.message)

      setTimeout(() => {
        window.location.reload()
      }, 3000)
    })
    .catch(error => {
      buttonWrapper.disabled = false
      buttonWrapper.classList.remove('ponter-events-none')
      button.classList.remove('animate-spin')
      toastr.error(`${error.message}`)
    });
  }
}

import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { get } from "@rails/request.js"

// Connects to data-controller="partner-select"
export default class extends Controller {
  static targets = ["currency", "exchangeRate", "partner", "exchangeDate", "invoiceId"]
  static values = { url: String , url2: String}

  partnerChange() {
    let params = new URLSearchParams()
    params.append('invoice_id', this.invoiceIdTarget.value)
    params.append('partner', this.partnerTarget.value)
    params.append('exchange_date', this.exchangeDateTarget.value)
    get(`${this.urlValue}?${params}`)
    .then(r => r.response.json())
    .then(data => {
      this.currencyTarget.slim.setSelected(`${data.currency_id}`)
    })
    if (this.partnerTarget.value) {
      fetch(`${this.url2Value}?${params}`, {
        headers: {
          Accept: "text/vnd.turbo-stream.html"
        }
      })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
    }
  }

  currencyChange() {
    let params = new URLSearchParams()
    params.append('partner', this.partnerTarget.value)
    params.append('currency', this.currencyTarget.value)
    params.append('exchange_date', this.exchangeDateTarget.value)
    get(`${this.urlValue}?${params}`)
    .then(r => r.response.json())
    .then(data => {
      this.exchangeRateTarget.value = data.exchange_rate
    })
  }
}

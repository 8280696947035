/* eslint no-console:0 */
//= require chartkick
//= require toastr

// Rails functionality
import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"
toastr = require("toastr")

// Make accessible for Electron and Mobile adapters
window.Rails = Rails
window.Turbo = Turbo

require("@rails/activestorage").start()
import "@rails/actiontext"

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

// Jumpstart Pro & other Functionality
import "./src/**/*"
require("local-time").start()

import "chartkick/chart.js"

// Turbo.setConfirmMethod((_message, element) => {
//   let containerElement = document.getElementById("confirm-dialog")
//   containerElement.innerHTML = `
//   <dialog id="turbo-confirm" class="rounded-lg dialog-border items-center justify-center flex p-0 w-[35%]">
//         <form method="dialog" class="w-full">
//           <div class="py-3 text-center">
//   <i class="fas fa-exclamation-circle text-[3rem] text-slate-800 my-6"></i>

//   </div>
//   <h4 class="text-danger-gradient text-center mt-4 mb-8"><%= t('sure_to_delete') %>űűű</h4>
//           <hr class="border border-b-3 border-solid border-[#dee2e6] w-full p-0 m-0"></hr>
//           <div class="p-4 rounded-lg flex items-center justify-end">
//             <button value="confirm" class="btnn btnn-secondary p-4 rounded-lg text-white font-normal">Megerősítés</button>
//             <button value="cancel" class="btnn-alt btnn-link p-4 rounded-lg font-normal">Mégsem</button>
//           </div>
//         </form>
//       </dialog>
//   `
//   let dialog = document.getElementById("turbo-confirm")
//   // dialog.querySelector("p").textContent = message
//   dialog.classList.toggle("block")
//   // dialog.showModal()

//   return new Promise((resolve, reject) => {
//     dialog.addEventListener("close", () => {
//       resolve(dialog.returnValue == "confirm")
//       dialog.classList.toggle("block")
//       dialog.close()
//     }, { once: true })
//     dialog.parentElement.removeAttribute("src") // it might be nice to also remove the modal SRC
//   })
// })

// Navbar notifications dropdown

// window.addEventListener("click", function (e) {
//   if (dropdown_trigger && dropdown_menu) {
//     if (!dropdown_menu.contains(e.target) && !dropdown_trigger.contains(e.target)) {
//       if (dropdown_trigger.getAttribute("aria-expanded") == "true") {
//         dropdown_trigger.click();
//       }
//     }
//   }
// });

document.addEventListener('turbo:load', function() {
  let dropdown_trigger = document.querySelector("[dropdown-trigger]")
  if (dropdown_trigger) {
    let dropdown_menu = dropdown_trigger.parentElement.querySelector("[dropdown-menu]");

    dropdown_trigger.addEventListener("click", (event) => {
      dropdown_menu.classList.toggle("opacity-0");
      dropdown_menu.classList.toggle("pointer-events-none");
      dropdown_menu.classList.toggle("before:-top-5");

      if (dropdown_trigger.getAttribute("aria-expanded") == "false") {
        document.body.classList.add('overflow-hidden')
        dropdown_trigger.setAttribute("aria-expanded", "true");
        dropdown_menu.classList.remove("transform-dropdown");
        dropdown_menu.classList.add("transform-dropdown-show");
      } else {
        dropdown_trigger.setAttribute("aria-expanded", "false");
        dropdown_menu.classList.remove("transform-dropdown-show");
        dropdown_menu.classList.add("transform-dropdown");
        document.body.classList.remove('overflow-hidden')
      }
    });
  }
});

document.addEventListener('click', (e) => {
  let dropdown_trigger2 = document.querySelector("[dropdown-trigger2]")
  let dropdown_menu2 = document.querySelector("[dropdown-menu2]")
  let dropdown_trigger = document.querySelector("[dropdown-trigger]")
  const withinBoundaries = e.composedPath().includes(dropdown_menu2)
  const withinTriggerBoundaries = e.composedPath().includes(dropdown_trigger2)
  const withinAccountTriggerBoundaries = e.composedPath().includes(dropdown_trigger)

  if (dropdown_trigger2 && !withinBoundaries && !withinTriggerBoundaries && !withinAccountTriggerBoundaries && (dropdown_trigger2.getAttribute("aria-expanded") == "true")) {
    dropdown_menu2.classList.toggle("opacity-0");
    dropdown_menu2.classList.toggle("pointer-events-none");
    dropdown_menu2.classList.toggle("before:-top-5");
    dropdown_trigger2.setAttribute("aria-expanded", "false");
    dropdown_menu2.classList.remove("transform-dropdown-show");
    dropdown_menu2.classList.add("transform-dropdown");
  }
})

document.addEventListener('click', (e) => {
  let dropdown_trigger2 = document.querySelector("[dropdown-trigger2]")
  let dropdown_menu = document.querySelector("[dropdown-menu]");
  let dropdown_trigger = document.querySelector("[dropdown-trigger]")
  const withinBoundaries = e.composedPath().includes(dropdown_menu)
  const withinTriggerBoundaries = e.composedPath().includes(dropdown_trigger)
  const withinFilterTriggerBoundaries = e.composedPath().includes(dropdown_trigger2)

  if (dropdown_trigger2 && !withinBoundaries && !withinTriggerBoundaries && !withinFilterTriggerBoundaries && (dropdown_trigger.getAttribute("aria-expanded") == "true")) {
    dropdown_menu.classList.toggle("opacity-0");
    dropdown_menu.classList.toggle("pointer-events-none");
    dropdown_menu.classList.toggle("before:-top-5");
    dropdown_trigger.setAttribute("aria-expanded", "false");
    dropdown_menu.classList.remove("transform-dropdown-show");
    dropdown_menu.classList.add("transform-dropdown");
    document.body.classList.remove('overflow-hidden')
  }
})

document.addEventListener('turbo:load', function(e) {
  let dropdown_trigger2 = document.querySelector("[dropdown-trigger2]")

  if (dropdown_trigger2) {
    let dropdown_menu2 = document.querySelector("[dropdown-menu2]");
    let filterMobileClose = document.getElementById("filter-mobile-close")
    const viewportWidth = window.innerWidth;

    dropdown_trigger2.addEventListener("click", () => {
      dropdown_menu2.classList.toggle("opacity-0");
      dropdown_menu2.classList.toggle("pointer-events-none");
      dropdown_menu2.classList.toggle("before:-top-5");
      if (dropdown_trigger2.getAttribute("aria-expanded") == "false") {
        if (viewportWidth > 640) {
          document.body.classList.add('overflow-hidden')
        }
        dropdown_trigger2.setAttribute("aria-expanded", "true");
        dropdown_menu2.classList.remove("transform-dropdown");
        dropdown_menu2.classList.add("transform-dropdown-show");
      } else {
        dropdown_trigger2.setAttribute("aria-expanded", "false");
        dropdown_menu2.classList.remove("transform-dropdown-show");
        dropdown_menu2.classList.add("transform-dropdown");
        if (viewportWidth > 640) {
          document.body.classList.remove('overflow-hidden')
        }
      }
    });

    filterMobileClose.addEventListener("click", () => {
      dropdown_menu2.classList.toggle("opacity-0");
      dropdown_menu2.classList.toggle("pointer-events-none");
      dropdown_menu2.classList.toggle("before:-top-5");
      if (dropdown_trigger2.getAttribute("aria-expanded") == "false") {
        if (viewportWidth > 640) {
          document.body.classList.add('overflow-hidden')
        }
        dropdown_trigger2.setAttribute("aria-expanded", "true");
        dropdown_menu2.classList.remove("transform-dropdown");
        dropdown_menu2.classList.add("transform-dropdown-show");
      } else {
        dropdown_trigger2.setAttribute("aria-expanded", "false");
        dropdown_menu2.classList.remove("transform-dropdown-show");
        dropdown_menu2.classList.add("transform-dropdown");
        if (viewportWidth > 640) {
          document.body.classList.remove('overflow-hidden')
        }
      }
    })
  }
});

document.addEventListener('turbo:load', function() {
  let dropdown_trigger3 = document.querySelector("[dropdown-trigger3]")

  if (dropdown_trigger3) {
    let dropdown_menu3 = dropdown_trigger3.parentElement.querySelector("[dropdown-menu3]");
    dropdown_trigger3.addEventListener("click", () => {
      dropdown_menu3.classList.toggle("opacity-0");
      dropdown_menu3.classList.toggle("pointer-events-none");
      dropdown_menu3.classList.toggle("before:-top-5");
      if (dropdown_trigger3.getAttribute("aria-expanded") == "false") {
        document.body.classList.add('overflow-hidden')
        dropdown_trigger3.setAttribute("aria-expanded", "true");
        dropdown_menu3.classList.remove("transform-dropdown");
        dropdown_menu3.classList.add("transform-dropdown-show");
      } else {
        dropdown_trigger3.setAttribute("aria-expanded", "false");
        dropdown_menu3.classList.remove("transform-dropdown-show");
        dropdown_menu3.classList.add("transform-dropdown");
        document.body.classList.remove('overflow-hidden')
      }
    });
  }
});

document.addEventListener("turbo:load", async (event) => {
  event.preventDefault()
  let buttonElement = document.getElementsByClassName("filter-button")[0]
  if (buttonElement) {
    let tableContainer = document.getElementsByClassName("table-containers")[0]
    let elDistanceToTop = window.pageYOffset + buttonElement.getBoundingClientRect().top
    let filterContainer = document.getElementsByClassName("filter-container")[0]
    let elDistanceToRight = window.innerWidth - tableContainer.getBoundingClientRect().right

    const changer = async () => {
      try {
        filterContainer.style.marginTop = `${elDistanceToTop / 5}px`
        filterContainer.style.marginRight = `${elDistanceToRight - 23}px`
      }
      catch (e) {
        console.log(e)
      }
    }

    await changer()
  }
});

// Start Rails UJS
Rails.start()
